import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

import Heading from 'components/heading/Heading'
import PageWrapper from 'components/layout/page-wrapper'
import OpenApp from 'components/open-app'
import { openDeepLink } from 'utils'

import 'static/confirm/scss/styles.scss'

const DefaultIcon = ({ icon, size }) => (
  <figure className="x__icon-container--animating">
    <img className={`x__icon--${size}`} src={icon} />
  </figure>
)

const DefaultHeadings = ({ page, section, subHeadingProps }) => (
  <div className="x__headings-container--animating">
    <div className="x__heading">
      <Heading page={page} section={section} />
    </div>
    <div className="x__subheading">
      <Heading type="h2" page={page} section={section} {...subHeadingProps} />
    </div>
  </div>
)

const REDIRECT_TIMEOUT = 4500

function ConfirmPage({
  icon,
  iconSize, // normal, larger, largest
  backgroundGradient,
  page,
  deepLinkPath,
  redirectUrl,
  mobileAppLink,
  location,
  section,
  subHeadingProps,
}) {
  const [animate, setAnimate] = useState(false)

  // Get state if URL has params.
  const success = location.search && new URL(location.href).searchParams.get('success')
  const error = !!success && parseInt(success) !== 1

  useEffect(() => setTimeout(setAnimate(true), 1), [])

  useEffect(() => {
    if (deepLinkPath) {
      setTimeout(() => openDeepLink(deepLinkPath), REDIRECT_TIMEOUT)
    } else if (redirectUrl) {
      setTimeout(() => {
        window.location.href = redirectUrl
      }, REDIRECT_TIMEOUT)
    }
  }, [deepLinkPath, redirectUrl])

  return (
    <PageWrapper
      className={classNames('x__page-confirm', {
        'x__page-confirm--animating': animate,
        'x__page-confirm--background-gradient': backgroundGradient,
      })}
      headerProps={{
        background: backgroundGradient ? 'transparent' : 'primary',
        minimal: true,
      }}
      removeFooter
    >
      {!!mobileAppLink && <OpenApp link={mobileAppLink} />}
      <DefaultIcon icon={icon} size={iconSize} />
      <DefaultHeadings
        page={page}
        section={section || (error ? 'error' : 'success')}
        subHeadingProps={subHeadingProps}
      />
    </PageWrapper>
  )
}

export default ConfirmPage
