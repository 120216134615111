import classNames from 'classnames'
import React from 'react'

import Header from '../Header'
import Footer from '../Footer'

import './styles.scss'

function PageWrapper({
  // Pages have a wrapper `x__[name]-page` class.
  // Specific page components are tied to this class.
  className,
  // Some pages use a different background color.
  // This prop receives the string for the new color.
  backgroundColor,
  // Header and Footer can have tracking and other props for content,
  // banners, and disclaimers. Pass those in the corresponding object.
  headerProps = {},
  footerProps = {},
  // There are a few pages that don't need a header or footer.
  removeHeader = false,
  removeFooter = false,
  //
  children,
}) {
  const wrapperProps = {
    className: classNames('exodus__page-wrapper', className),
    style: { backgroundColor },
  }

  return (
    <div {...wrapperProps}>
      {!removeHeader && <Header {...headerProps} />}
      <main className="x">{children}</main>
      {!removeFooter && <Footer {...footerProps} />}
    </div>
  )
}

export default PageWrapper
